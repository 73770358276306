import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';


import RootComponent from './root';
import IndexComponent from './container/indexScreen';
import LoginComponent from './container/auth/login/loginComponent';
import SwitchComponent from './container/auth/switch/switch';
import RegisterComponent from './container/auth/register/registerComponent';
import DashboardComponent from './container/dashboard/dashboard';
import PaymentHistoryComponent from './container/paymentHistory/paymentHistory';
import SmartTradersComponent from './container/smartTraders/smartTradersComponent';
import SettingComponent from './container/setting/setting';
import PaymentComponent from './container/payment/paymentComponent';
import ReferralComponent from './container/referral/referralComponent';
import ConfigurationComponent from './container/configuration/configurationComponent';



class RouterComponent extends Component {

    render() {
        return (

            <Router history={browserHistory}>
                <Route path="/login" component={LoginComponent} />
                <Route path="/register" component={RegisterComponent} />
                <Route path="/" component={SwitchComponent} />
 
                <Route path="/" component={RootComponent} >
                    <IndexRoute component={IndexComponent} />
                    <Route path="/dashboard" component={DashboardComponent} />
                    <Route path="/payment-history" component={PaymentHistoryComponent} />
                    <Route path="/smart-traders" component={SmartTradersComponent} />
                    <Route path="/setting" component={SettingComponent} />
                    <Route path="/configuration" component={ConfigurationComponent} />
                    <Route path="/payment" component={PaymentComponent} />
                    <Route path="/referral" component={ReferralComponent} />
                </Route>
            </Router>
        );
    }
}

export default RouterComponent;