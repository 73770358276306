import React, { Component } from 'react';
import UserInfoComponent from './userInfoComponent';
import GetToApi from '../../../controller/getToApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
class HeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: ''
        }
    }


    componentWillMount = async () => {

        try {
            const res = await GetToApi('profile/init');
            if (res.status === 200)
                return await this.setState({
                    fullName: res.data.full_name
                })
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }

    render() {
        return (
            <div className="nav-bar nav-bar-line row">
                <div className="col-12">
                    <UserInfoComponent fullName={this.state.fullName} />
                </div>
            </div>
        )
    }
}

export default HeaderComponent;