import React, { Component } from 'react';

import './style.scss'


class RadarsComponent extends Component {

    render() {
        return (
            <div>
           

                <svg
                    version="1.1"
                    id="radar-circle-other"
                    pointer-events="none">

                    <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#E7F2F0" stroke-width="16px" stroke-opacity="0.7">
                        <animate attributeName="r" from="0" to="1220" dur="6s" repeatCount="indefinite" begin="0.25s" />
                    </circle>

                    <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#E7F2F0" stroke-width="12px" stroke-opacity="0.7">
                        <animate attributeName="r" from="0" to="1220" dur="6s" repeatCount="indefinite" begin="1.25s" />
                    </circle>

                    <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#E7F2F0" stroke-width="8px" stroke-opacity="0.7">
                        <animate attributeName="r" from="0" to="1220" dur="6s" repeatCount="indefinite" begin="2.25s" />
                    </circle>

                    <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#E7F2F0" stroke-width="4px" stroke-opacity="0.7">
                        <animate attributeName="r" from="0" to="1220" dur="6s" repeatCount="indefinite" begin="3.25s" />
                    </circle>

                    <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#E7F2F0" stroke-width="2px" stroke-opacity="0.7">
                        <animate attributeName="r" from="0" to="1220" dur="6s" repeatCount="indefinite" begin="4.25s" />
                    </circle>

                </svg>
            </div>
        );
    }
}

export default RadarsComponent;