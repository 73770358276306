import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import './style.scss'


const BorderLinearProgress = withStyles({
    root: {
        height: 20,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: '#DAEEEA',
    },
    bar: {
        borderRadius: 10,
        backgroundColor: '#44AA95',
    },
})(LinearProgress);

const normalise = (value, minValue, maxValue) => (value - minValue) * 100 / (maxValue - minValue);


class ProgressComponent extends Component {

    render() {

        return (
            <div>
                <h4 className="pb-3">{this.props.title}</h4>
                <div className="progress-header-container">
                    <div className="progress-header-item">
                        <h4>{this.props.minValue}</h4>
                        <p>{this.props.minTitle}</p>
                    </div>
                    <div className="progress-header-item">
                        <h4>{this.props.maxValue}</h4>
                        <p>{this.props.maxTitle}</p>
                    </div>
                </div>
                <BorderLinearProgress variant="determinate" value={normalise(this.props.progressValue, 0, this.props.maxValue)} />
                <div className="downInformation">
                    <div className="progress-footer-item">
                        <p>{this.props.firstTitle}</p>
                        <p><b>{this.props.firstValue}</b></p>
                    </div>
                    <div className="progress-footer-item">
                        <p>{this.props.secondTitle}</p>
                        <p><b>{this.props.secondValue}</b></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProgressComponent;