import React, { Component } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import SweetAlert from 'sweetalert-react'
import GetToApi from '../../controller/getToApi';
import PostToApi from '../../controller/postToApi';
import './style.scss';
class PaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copySuccess: 'Copy',
            network: 'TRC20',
            totalFee: this.props.location.state.fee,
            budget: this.props.location.state.budget
        }
    }

    componentWillMount = async () => {
        this.getWalletAddressInfo()
    }

    getWalletAddressInfo = async () => {

        try {
            const res = await GetToApi('wallet/address');
            if (res.status === 200) {
                console.log(res.data)
                return await this.setState({
                    exchange: res.data[0].exchange,
                    coin: res.data[0].coin,
                    address: res.data[0].address,
                    network: res.data[0].network,
                    walletAddressId: res.data[0].id
                })
            }
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }

    paymentOrder = async () => {
        if (this.state.txId) {

            const data = new FormData();
            data.append('tx_id', this.state.txId);
            data.append('budget', this.state.budget);
            data.append('wallet_address_id', this.state.walletAddressId);

            const res = await PostToApi(data, 'payment');
            if (res.status === 200) {
                this.setState({
                    show: true,
                    alertType: 'success',
                    alertTitle: 'Connected ;)',
                    alertText: 'your Payment was success'
                });

            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                });
            }
        }
        else {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: 'please insert your txID'
            });
        }
    }


    copyCodeToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
        this.setState({
            copySuccess: 'Copied'
        })
    }
    SelectAll = () => {
        const el = this.textArea
        el.select()
    }

    navigateTo(key) {
        browserHistory.push(key);
    }

    changedHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    onConfirmMessage(type) {
        this.setState({ show: false })
        if(type === 'success'){

            this.navigateTo('/payment-history')
        }
    }

    render() {

        return (
            <div className="p-3">
                <SweetAlert
                    show={this.state.show} 
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => {
                       this.onConfirmMessage(this.state.alertType)
                    }}
                />
                <div className="form-title">
                    <h4>Payment Method</h4>
                    <p>you can pay easily and fast with <b>{this.state.coin}</b>.</p>
                </div>

                <div className="row pb-5">
                    <div className="col-lg-9 col-sm-12 pt-5">
                        <div className="pb-5">
                            <label htmlFor="basic-url">bibiTrade Wallet Address</label>
                            <InputGroup className="mb-3"  >
                                <FormControl
                                    placeholder="walletAddress"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    ref={(textarea) => this.textArea = textarea}
                                    value={this.state.address}
                                    onClick={() => this.SelectAll()}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" className="yellow-btn" onClick={() => this.copyCodeToClipboard()}>{this.state.copySuccess}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>

                        <div>
                            <label htmlFor="basic-url">Your Withdrawal Txid</label>
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Internal transfer XXXXXXXXXXXXXX"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={this.state.txId}
                                    id="txId"
                                    onChange={this.changedHandler}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" className="yellow-btn">Paste</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <label htmlFor="basic-url">The dear user finally enters the transaction confirmation link up to 10 minutes after the deposit and subscribes to your plan.</label>

                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="wallet-barcode-container">
                            <h4>{<b>{this.state.network}</b>}</h4>
                            <div className="barcode-image" style={{ backgroundImage: `url("https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${this.state.address}")` }}></div>
                        </div>
                    </div>
                </div>
                <div className="payment-total-fee-container mt-2">
                    <div className="payment-fee">
                        <p>Total fee</p>
                        <h1>${this.state.totalFee}.00</h1>
                    </div>
                    <div className="btn-payment" onClick={this.paymentOrder}>
                        <p>Pay it</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentComponent;