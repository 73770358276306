import React, { Component } from 'react';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faRobot, faBullhorn, faFileInvoiceDollar , faCog } from '@fortawesome/free-solid-svg-icons'

import './style.scss';



class SideLeftMenuItemComponent extends Component {

    render() {

        return (
            <ul>
                <li className="side-menu">
                    <Link to="/dashboard" className="link-item">
                        <div className="menu-item-container">
                            <div className="menu-icon-container">
                                <FontAwesomeIcon icon={faHome} className="menu-icon" />
                            </div>
                            <span className="side-menu-title" >Dashboard</span>
                        </div>
                    </Link>
                </li>
                <li className="side-menu">
                    <Link to="/smart-traders" className="link-item">
                        <div className="menu-item-container">
                            <div className="menu-icon-container">
                                <FontAwesomeIcon icon={faRobot} className="menu-icon" />
                            </div>
                            <span className="side-menu-title" >Smart Traders</span>
                        </div>
                    </Link>
                </li>
                <li className="side-menu">
                    <Link to="/payment-history" className="link-item">
                        <div className="menu-item-container">
                            <div className="menu-icon-container">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} className="menu-icon" />
                            </div>
                            <span className="side-menu-title" >Payment History</span>
                        </div>
                    </Link>
                </li>
                <li className="side-menu">
                    <Link to="/referral" className="link-item">
                        <div className="menu-item-container">
                            <div className="menu-icon-container">
                                <FontAwesomeIcon icon={faBullhorn} className="menu-icon" />
                            </div>
                            <span className="side-menu-title" >Refer a Friend</span>
                        </div>
                    </Link>
                </li>
                <li className="side-menu">
                    <Link to="/setting" className="link-item">
                        <div className="menu-item-container">
                            <div className="menu-icon-container">
                                <FontAwesomeIcon icon={faCog} className="menu-icon" />
                            </div>
                            <span className="side-menu-title" >Setting</span>
                        </div>
                    </Link>
                </li>



            </ul>
        )
    }
}

export default SideLeftMenuItemComponent;