import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Token from './api/token';
import HeaderComponent from './component/dashboard/header/headerComponent';
import SideLeftMenuComponent from './component/dashboard/sideLeftMenu/sideLeftMenuComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars, faBullhorn, faFileInvoiceDollar, faCog } from '@fortawesome/free-solid-svg-icons'


import './root.css'

export default class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuStatus: false
        }
    }

    componentWillMount = async () => {
        if (Token === null) {
            browserHistory.push('/login');
        } else {
            browserHistory.push('/dashboard');

        }
    }

    closeMenu = (status) => {
        this.setState({
            menuStatus: status
        })
    }

    render() {
        return (
            <div className="bg-cover" >
                {/* ---------------  Mobile Content ----------- */}
                <div className="user-info-container-mobile">
                    <div className="logo-mobile"></div>
                    <div className="navbar-icon" onClick={() => this.closeMenu(true)}>
                        <FontAwesomeIcon icon={faBars} className="menu-icon" />
                    </div>
                </div>
                {this.state.menuStatus ? <div className="menu-content-mobile bg-cover">
                    <div className="close-icon" onClick={() => this.closeMenu(false)}>
                        <FontAwesomeIcon icon={faTimes} className="menu-icon" />
                    </div>
                    <div onClick={() => this.closeMenu(false)}>
                        <SideLeftMenuComponent />
                    </div>
                </div> : null}

                {/* ---------------  Mobile Content ----------- */}
                <div className="master-container" >
                    <div className="menu-left-content">
                        <SideLeftMenuComponent />
                    </div>
                    <div className="main-content">
                        <div className="custom-main-content">
                            <HeaderComponent />
                            <div className="overflow-screen">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
