import React, { Component } from 'react';
import './style.scss';

class SideLeftMenuLogoComponent extends Component {

    render() {

        return (
            <div className="logo"></div>
        )
    }
}

export default SideLeftMenuLogoComponent;