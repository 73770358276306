import base from '../api/baseURL';
import Token from '../api/token';


function postApi(data, key) {
    let url = base.baseURL + key;

    return fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers: {
            // "Content-Type": "application/json",
            "Accept": "application/json",
            "agent": "web",
            "Authorization": Token
        },
        redirect: "follow",
        referrer: "no-referrer",
        // body: JSON.stringify(data), 
        body: data
    }).then(response => {
        const statusCode = response.status
        const data = response.json()
        return Promise.all([statusCode, data])
    }).then(([res, data]) => {
        return ({ 'status': res, 'data': data.data, 'meta': data.meta, 'error': data.error, 'isLoading': false })
    })

}


export default postApi;
