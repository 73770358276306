import React, { Component } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import ProgressComponent from '../../component/dashboard/progress/progressComponent';
import SweetAlert from 'sweetalert-react'
import PostToApi from '../../controller/postToApi';
import GetToApi from '../../controller/getToApi';
import './style.scss';

class ReferralComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copySuccess: 'Copy',
            email: '',
            referralInfo:{},
            referralPercent:2
        }
    }

    componentWillMount = async () => {
        this.getReferralInfo();
    }


    copyCodeToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
        this.setState({
            copySuccess: 'Copied'
        })
    }
    SelectAll = () => {
        const el = this.textArea
        el.select()
    }

    changedHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    getReferralInfo = async() =>{

        try {
            const res = await GetToApi('referral');
            if (res.status === 200)
                return await this.setState({
                    referralInfo: res.data,
                    email:''
                })
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }

    sendInvitationToFriend = async () => {

        const data = new FormData();
        data.append('email', this.state.email);

        const res = await PostToApi(data, 'referral');
        if (res.status === 200) {
            this.setState({
                show: true,
                alertType:'success',
                alertTitle:'Congratulation ;)',
                alertText: 'your invitation has been sent!'
            });
            this.getReferralInfo();
        } else {
            this.setState({
                show: true,
                alertType:'error',
                alertTitle:'Something was wrong!',
                alertText: res.error
            });
        }


    }

    render() {

        return (
            <div className="p-3">

                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => this.setState({ show: false })}
                />

                <div className="form-title">
                    <h4>Refer a Friend</h4>
                    <p>You and your friends (who've never had a bibiTrade account previously) all get up to <b>{this.state.referralPercent}%</b> each after they upgrade to a paid plan. Complete details in terms & conditions</p>
                </div>

                <div className="row p-3 referral-address-container">


                    <label htmlFor="basic-url">Your Friend Email Address</label>


                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Email@Example.com"
                            aria-label="Email@Example.com"
                            aria-describedby="basic-addon2"
                            value={this.state.email}
                            id="email"
                            onChange={this.changedHandler}
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={this.sendInvitationToFriend}>Send Invitation</Button>
                        </InputGroup.Append>
                    </InputGroup>



                </div>

                <div className="row pt-5">

                    <div className="col-lg-6 col-md-12 pt-3">
                        <ProgressComponent
                            minValue={this.state.referralInfo.count_all}
                            minTitle="person"
                            maxValue={this.state.referralInfo.limit}
                            maxTitle="person"
                            title="Limits"
                            firstTitle="Signed up for free accounts"
                            firstValue={this.state.referralInfo.count_signup}
                            secondTitle=""
                            secondValue=""
                            progressValue={this.state.referralInfo.count_all}
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 referral-gain-container">
                        <div className="referral-fee">
                            <h4>corporation Profit</h4>
                            <h1>${this.state.referralInfo.corporation_Profit}</h1>
                        </div>
                        <div className="btn-request">
                            <p>Withdrawal</p>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default ReferralComponent;
