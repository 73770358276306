import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@material-ui/core';
import DeleteToApi from '../../../controller/deleteToApi';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.scss';

class ApiKeyRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exchange: 'binance',
            show: false,
            alertTitle: ''
        }
    }

    removeApiKey = async () => {
        console.log(this.props.apiTokenData[0].id)
        const res = await DeleteToApi('setting/' + this.props.apiTokenData[0].id);
        if (res.status === 200) {
            this.setState({
                show: false,
            })
            this.props.onRemoveApiKeyItem({});
        } else {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: res.error
            })
        }



    }

    showRemoveMessage = async () => {
        this.setState({
            show: true,
            alertType: 'warning',
            alertTitle: 'Remove!',
            alertText: 'Are you sure to delete this item?'
        })
    }

    render() {

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    showCancelButton
                    onConfirm={() => this.removeApiKey()}
                    onCancel={() => {
                        this.setState({ show: false });
                    }}
                />
                <div className="api-key-container mt-5">
                    <div className="api-key-icon-container">
                        <div className="exchange-icon"></div>
                        <p>Binance Exchange</p>
                    </div>
                    <div className="api-key-icon-container">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon-api-key" />
                        <p>API Key</p>
                    </div>
                    <div className="api-key-icon-container">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon-api-key" />
                        <p>Secret Key</p>
                    </div>
                    <div className="api-key-icon-container">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon-api-key" />
                        <p>Connected</p>
                    </div>
                    <div>
                        <Button startIcon={<DeleteIcon />} variant="contained" color="secondary" onClick={this.showRemoveMessage}>
                            Remove
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}


export default ApiKeyRecord;