import React, { Component } from 'react';

import './style.scss'


class CoinBoxComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        }
    }
    render() {
        return (
            <div>
   
                <div className="coin-box" style={{width: this.props.width + 'px', height: this.props.height + 'px'}}>
                    <p>{this.props.coin}</p>
                </div>
               
            </div>
        );
    }
}

export default CoinBoxComponent;