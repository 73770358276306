import React, { Component } from 'react';
import CoinBoxComponent from '../../component/dashboard/smartTraders/coinBox/radarsComponent';
import BotHeaderComponent from './botHeader/botHeaderComponent';
import BotOrdersComponent from './botOrders/botOrdersComponent';

import './style.scss'


class SmartTradersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botStatus: false
        }
    }

    render() {

        return (
            <div>
                <div className="form-container">
                    <BotHeaderComponent />
                    <BotOrdersComponent />


                    {this.state.botStatus ?
                        <div className="radar-not-container">
                            <h3>Keep Calm!</h3>
                            <p>we are looking for the best hunt for you . . .</p>
                            <div className="just-believe">
                                <p>Just believe it</p>
                            </div>
                        </div>
                        :

                        <div className="coin-box-container">
                            <CoinBoxComponent coin="btc" width="60" height="60" />
                            <CoinBoxComponent coin="usd" width="45" height="45" />
                            <CoinBoxComponent coin="zxr" width="80" height="80" />
                            <CoinBoxComponent coin="blz" width="70" height="70" />
                            <CoinBoxComponent coin="eer" width="50" height="50" />
                            <CoinBoxComponent coin="aas" width="75" height="75" />
                            <CoinBoxComponent coin="yip" width="90" height="90" />
                            <CoinBoxComponent coin="jst" width="60" height="60" />
                            <CoinBoxComponent coin="qwe" width="75" height="75" />
                            <CoinBoxComponent coin="rrr" width="100" height="100" />
                            <CoinBoxComponent coin="tha" width="80" height="80" />
                            <CoinBoxComponent coin="twt" width="50" height="50" />
                            <CoinBoxComponent coin="opo" width="70" height="70" />
                            <CoinBoxComponent coin="noo" width="85" height="85" />
                            <CoinBoxComponent coin="noo" width="50" height="50" />
                            <CoinBoxComponent coin="noo" width="60" height="60" />
                            <CoinBoxComponent coin="noo" width="100" height="100" />
                        </div>
                    }





                </div>
            </div>
        );
    }
}

export default SmartTradersComponent;