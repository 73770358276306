import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import SweetAlert from 'sweetalert-react'
import PostToApi from '../../../controller/postToApi';
import GetToApi from '../../../controller/getToApi';
import ApiKeyRecord from './apiKeyRecord';
import './style.scss';

class AccessPointComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exchange: 'binance',
            apiKey: '',
            apiSecret: '',
            apiToken: {},
            alertTitle: ''
        }
    }
    componentWillMount = async () => {
        await this.getApiKeyFromExchange();
    }

    changedHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    addApiKeyToExchange = async () => {

        const data = new FormData();
        data.append('exchange', this.state.exchange);
        data.append('api_key', this.state.apiKey);
        data.append('api_secret', this.state.apiSecret);

        const res = await PostToApi(data, 'setting');
        if (res.status === 200) {
            this.setState({
                show: true,
                alertType: 'success',
                alertTitle: 'Connected ;)',
                alertText: 'your API token connected to exchange!'
            });
            this.getApiKeyFromExchange();
        } else {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: res.error
            });
        }

    }

    getApiKeyFromExchange = async () => {

        try {
            const res = await GetToApi('setting');
            if (res.status === 200) {
                return await this.setState({
                    apiToken: res.data
                })
            }
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }


    handleLanguage = (langValue) => {
        this.setState({ apiToken: langValue })
    }

    render() {

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => this.setState({ show: false })}


                />
                <div className="form-title">
                    <h4>Connect to your Exchange</h4>
                    <p>for automatic and manual trading you can connect your bibitrade account to your owen exchange.</p>
                </div>
                <div className="pt-5">
                    <Form>
                        <Form.Group controlId="exchange">
                            <Form.Label>Exchange</Form.Label>
                            <Form.Control as="select">
                                <option>BINANCE</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="apiKey">
                            <Form.Label>API Key</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.apiKey} id="apiKey" onChange={this.changedHandler} />
                        </Form.Group>
                        <Form.Group controlId="apiSecret">
                            <Form.Label>Secret Key</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.apiSecret} id="apiSecret" onChange={this.changedHandler} />
                        </Form.Group>
                    </Form>
                </div>

                <div className="btn-container pb-5">
                    <div className="btn-save-primary" onClick={this.addApiKeyToExchange} >
                        <p>Save</p>
                    </div>
                </div>
                {this.state.apiToken.length > 0 ? <ApiKeyRecord apiTokenData={this.state.apiToken} onRemoveApiKeyItem={this.handleLanguage} /> : ''}


            </div>
        );
    }
}


export default AccessPointComponent;