import React, { Component } from 'react';
import ProgressComponent from '../../component/dashboard/progress/progressComponent';
import ChartComponent from '../../component/dashboard/chart/chartComponent';

import './style.scss'

class DashboardComponent extends Component {

    render() {

        return (
            <div className="p-3">
                <div>
                    <ChartComponent />
                </div>

                <div className="row pt-5">

                    <div className="col-lg-6 col-md-12">
                        <ProgressComponent
                            minValue={10}
                            minTitle="%"
                            maxValue={30}
                            maxTitle="%"
                            title="Smart Traders"
                            firstTitle="Total trades"
                            firstValue={950}
                            secondTitle=""
                            secondValue=""
                            progressValue={10}
                        />
                    </div>
                    <div className="col-lg-6 col-md-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardComponent;