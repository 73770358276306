import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ChartComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Profit',
                data: [11, 32, 45, 32, -5, 52, 41,10,15,50,5]
            }],
            options: {
                chart: {
                    height: 500,
                    type: 'area',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2021-10-20T00:00:00.000Z", "2021-10-21T01:30:00.000Z", "2021-10-22T02:30:00.000Z", "2021-10-23T03:30:00.000Z", "2021-10-24T04:30:00.000Z", "2021-10-25T05:30:00.000Z", "2021-10-26T06:30:00.000Z", "2021-10-27T06:30:00.000Z", "2021-10-28T06:30:00.000Z", "2021-10-29T06:30:00.000Z", "2021-10-30T06:30:00.000Z"]
                },

                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                        fontSize: '14px',
                        fontFamily: undefined
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: true,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: true,
                    },
                    items: {
                        display: 'flex',
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#44AA95',
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    }
                },
                noData: {
                    text: undefined,
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                zoom: {
                    enabled: false,
                },
                selection: {
                    enabled: false,
                }
            },




        };
    }



    render() {
        return (


            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={500} />
            </div>
        );
    }
}

export default ChartComponent;