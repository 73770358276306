import React, { Component } from 'react';
import SideLeftMenuTrustPilotComponent from './sideLeftMenuTrustPilotComponent';
import SideLeftMenuItemComponent from './sideLeftMenuItemComponent'
import SideLeftMenuLogoComponent from './sideLeftMenuLogoComponent'
import './style.scss';

class sideLeftMenuComponent extends Component {

    render() {

        return (

            <div className="side-left-container">
                <SideLeftMenuLogoComponent />
                <SideLeftMenuItemComponent />
                <SideLeftMenuTrustPilotComponent />
            </div>

        )
    }
}

export default sideLeftMenuComponent;