import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import RegisterFormComponent from './registerFormComponent';
import Token from '../../../api/token';
import '../style.scss';

class RegisterComponent extends Component {

    componentWillMount = async () => {
        if (Token === null) {
            browserHistory.push('/register');
        } else {
            browserHistory.push('/dashboard');

        }
    }

    render() {

        return (
            <div className="container-fluid cover">
                <div className="login-form-container">
                    <div className="logo-login"></div>
                    <RegisterFormComponent />
                </div>

            </div>
        );
    }
}

export default RegisterComponent;