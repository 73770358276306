import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Token from '../../../api/token';   
import './sweetalert.scss';

class SwitchComponent extends Component {

    componentDidMount = async() => {
        if(Token === null){ 
            browserHistory.push('/login');
        } else {
            browserHistory.push('/dashboard');

        }
    }


    render() {

        return (
            <div className="container-fluid cover">
                <h3>switch</h3>
            </div>
        );
    }
}

export default SwitchComponent;