import base from '../api/baseURL';
import Token from '../api/token';


function deleteToApi(key) {
    let url = base.baseURL + key;

    return fetch(url, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
            "Accept": "application/json",
            "agent": "web",
            "X-Debug": 1,
            "Authorization": Token,
        },
        redirect: "follow",
        referrer: "no-referrer",

    }).then(response => {
        const statusCode = response.status
        const data = response.json()
        return Promise.all([statusCode, data])
    }).then(([res, data]) => {
        return ({ 'status': res, 'data': data.data, 'meta': data.meta, 'error': data.error, 'isLoading': false })
    })

}


export default deleteToApi;

