import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { TextField } from '@material-ui/core';
import PostToApi from '../../../controller/postToApi';
import SweetAlert from 'sweetalert-react'

class RegisterFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            password: '',
            country: 'iran'
        }
    }


    navigateTo(key) {
        browserHistory.push(key);
    }

    changedHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    registerUserByEmail = async () => {

        const data = new FormData();
        data.append('full_name', this.state.fullName);
        data.append('email', this.state.email);
        data.append('password', this.state.password);
        data.append('country', this.state.country);

        const res = await PostToApi(data, 'auth/email/register');
        if (res.status === 200) {
            await localStorage.setItem('@tokenBibiTrade', res.data.token); 
            window.location.reload();
         } else {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: res.error
            });
        }

    }


    render() {
        return (
            <div className="container login-form-component">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => {
                        this.setState({ show: false })
                        
                    }}
                />
                <div className="row">
                    <h1 className="signIn">Sign Up</h1>
                </div>
                <div className=" d-flex flex-column">
                    <p className="pb-2 note-text">Please check that you are visiting the correct URL</p>
                    <p className="verify-url"><span>https://</span>accounts.bibitrade.com</p>
                </div>
                <div className="row pt-5">
                    <div>
                        <div className="col">
                            <TextField type="text" className="login-input" value={this.state.fullName} id="fullName" onChange={this.changedHandler} label="Full Name" />
                        </div>
                        <div className="col">
                            <TextField type="email" className="login-input" value={this.state.email} id="email" onChange={this.changedHandler} label="Email" />
                        </div>
                        <div className="col">
                            <TextField type="password" className="login-input" value={this.state.password} id="password" onChange={this.changedHandler} label="Password" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="btn-login" onClick={() => this.registerUserByEmail()}>
                        <p>Sign Up</p>
                    </div>
                </div>
                <div className="row">
                    <div className="btn-login-stork" onClick={() => this.navigateTo('/login')}>
                        <p>Sign in</p>
                    </div>
                </div>
            </div>

        );
    }
}

export default RegisterFormComponent;