import { Button } from '@material-ui/core';
import { browserHistory } from 'react-router';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { Component } from 'react';

import './style.scss';

class BotHeaderComponent extends Component {

    navigateTo(key) {
        browserHistory.push(key);
    }

    render() {
        return (
            // <ul className="bot-header-container">


            //     <li>
            //         <Button variant="contained" color="primary" onClick={() => this.navigateTo('/configuration')}>
            //         Configuration
            //         </Button>

            //     </li>

            // </ul>

            <div className="row  align-items-center p-2 bot-header-container">
                <div className="col-lg-12">
                    <ul className="header-ct d-flex justify-content-between align-items-center  m-0 p-0 ">
                        <li>
                            <p>Budget</p>
                            <b>$900</b>
                        </li>
                        <li>
                            <p>Daily Profit</p>
                            <b>$900/</b>
                            <b>0%</b>
                        </li>
                        <li>
                            <p>Total Profit</p>
                            <b>$900/</b>
                            <b>0%</b>
                        </li>
                        <li>
                            <Button startIcon={<SettingsIcon />} variant="contained" color="primary" onClick={() => this.navigateTo('/configuration')}>
                                Setup
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default BotHeaderComponent;