import React, { Component } from 'react';
import BudgetSliderComponent from '../../component/dashboard/smartTraders/budgetSliderComponent';
import SweetAlert from 'sweetalert-react'
import GetToApi from '../../controller/getToApi';
import './style.scss';

class ConfigurationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            min: 600,
            max: 10000,
            periods: 30,
            profitPercent: 30,
            feePercentSetting: 9,
        }

    }
    componentWillMount = async () => {
        this.getAppSetting();
    }

    getAppSetting = async () => {

        try {
            const res = await GetToApi('app/setting');
            if (res.status === 200) {
                console.log(res.data)
                return await this.setState({
                    min: res.data.min_budget,
                    max: res.data.max_budget,
                    periods: res.data.periods,
                    profitPercent: res.data.profit_percent,
                    feePercentSetting: res.data.fee_percent,
                })
            }
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }

    render() {
        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => this.setState({ show: false })}
                />
                <div className="form-title">
                    <h4>Bot Configuration</h4>
                    <p>for the configuration of your bot, you need to set up your monthly budget and pay your subscription fee.</p>
                </div>
                <div className="bot-price-container">
                    <BudgetSliderComponent
                        min={this.state.min}
                        max={this.state.max}
                        periods={this.state.periods}
                        profitPercent={this.state.profitPercent}
                        feePercentSetting={this.state.feePercentSetting} />
                </div>
            </div>
        );
    }
}

export default ConfigurationComponent;