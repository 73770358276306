import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import './style.scss';

class sideLeftMenuTrustPilotComponent extends Component {

    render() {

        return (

            <ul className="trust-pilot-container">
                <li>
                    <div className="trust-pilot-link"></div>
                </li>
                <li className="slogan">
                    <span>Made with</span>
                    <FontAwesomeIcon icon={faHeart}  style={{color: "#308271"}} />
                    <span>for you.</span>
                </li>
                <li>
                    <p className="copy">© bibiTrade   2021</p>
                </li>
            </ul>

        )
    }
}

export default sideLeftMenuTrustPilotComponent;