import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import LoginFormComponent from './loginFormComponent';
import Token from '../../../api/token';
import '../style.scss';

class LoginComponent extends Component {

    componentWillMount = async() => {
        if(Token === null){ 
            browserHistory.push('/login');
        } else {
            browserHistory.push('/dashboard');

        }
    }

    render() {

        return (
            <div className="container-fluid cover">
                 <div className="login-form-container">
                 <div className="logo-login"></div>
                    <LoginFormComponent />
                </div> 
              
            </div>
        );
    }
}

export default LoginComponent;