import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react'
import PostToApi from '../../../controller/postToApi';

import { TextField } from '@material-ui/core';

class LoginFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            email: '',
            password: ''
        }
    }

    loginForm = async () => {

        const data = new FormData();
        data.append('email', this.state.email);
        data.append('password', this.state.password);

        const res = await PostToApi(data, 'auth/email/login');
        if (res.status === 200) { 

            await localStorage.setItem('@tokenBibiTrade', res.data.token); 
            window.location.reload();
        } else {
            console.log(res)
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: res.error

            });
        }
    }

    changedHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        return (
            <div className="container login-form-component">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => this.setState({ show: false })}
                />
                <div className="row">
                    <h1 className="signIn">Sign In</h1>
                </div>
                <div className=" d-flex flex-column">
                    <p className="pb-2 note-text">Please check that you are visiting the correct URL</p>
                    <p className="verify-url"><span>https://</span>accounts.bibitrade.com</p>
                </div>
                <div className="row pt-5">
                    <div>
                        <div className="col">
                            <TextField type="email" className="login-input" value={this.state.email} id="email" onChange={this.changedHandler} label="Email" />
                        </div>
                        <div className="col">
                            <TextField type="password" className="login-input" value={this.state.password} id="password" onChange={this.changedHandler} label="Password" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="btn-login" onClick={() => this.loginForm()}>
                        <p>Sign in</p>
                    </div>
                </div>
            </div>

        );
    }
}

export default LoginFormComponent;