import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import GetToApi from '../../controller/getToApi';
import SweetAlert from 'sweetalert-react'
import './style.css'

class PaymentHistoryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    componentWillMount() {
        this.getPaymentHistory();
    }


    getPaymentHistory = async () => {
        try {
            const res = await GetToApi('payment');
            if (res.status === 200) { 
                return await this.setState({
                    paymentHistory: res.data
                })
            }
            else if (res.status === 401) {
                await localStorage.removeItem('@tokenBibiTrade');
                window.location.reload();
            } else {
                this.setState({
                    show: true,
                    alertType: 'error',
                    alertTitle: 'Something was wrong!',
                    alertText: res.error
                })
            }

        } catch (error) {
            this.setState({
                show: true,
                alertType: 'error',
                alertTitle: 'Something was wrong!',
                alertText: error
            })
        }
    }

    timeConverter(value) {
        if (value) {
            const d = new Date(value * 1000);
            return  d.getFullYear() + '/' + (d.getMonth()+1) + '/' + d.getDate() ;
        }
    }

    render() {
        const renderPaymentHistory = (
            this.state.paymentHistory ? this.state.paymentHistory.map((item, index) => {
                return <tr key={index} >
                    <td>{index + 1}</td>
                    <td>${item.price}</td>
                    <td>{this.timeConverter(item.created_at)}</td>
                    <td>{item.type}</td>
                    <td>{item.state}</td>
                    <td>{item.address}</td>
                    <td>{item.tx_id}</td>
                </tr>
            }) : ''
        );
        return (
            <div className="p-3">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.alertType}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    onConfirm={() => this.setState({ show: false })}
                />

                <div className="form-title">
                    <h4>Payment Invoice</h4>
                    <p>A list of all your payments is visible</p>
                </div>

                <div className="table-responsive pt-5">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th className="td-min">Fee</th>
                                <th className="td-min">Date</th>
                                <th className="td-min">Type</th>
                                <th className="td-min">Status</th>
                                <th className="td-min">Wallet Address</th>
                                <th className="td-min">Txid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderPaymentHistory}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default PaymentHistoryComponent;