/*

Get TOken Component from localStorg

*/

 
function getToken(token){
    if(token !== null)
        return token
        
    return null
}

const Token = getToken(localStorage.getItem('@tokenBibiTrade'))


export default Token;

