import React, { Component } from 'react';
import { browserHistory } from 'react-router'; 
 

class IndexComponent extends Component {

    componentWillMount = () => { 
        
            browserHistory.push('/dashboard')
    } 

    render() {

        return (
            <div className="container-fluid">
                <h1>Index Component</h1> 
            </div>
        );
    }
}

export default IndexComponent;