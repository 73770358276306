import React, { Component } from 'react';

class LogoutComponent extends Component {



    logout = async () => {

        await localStorage.removeItem('@tokenBibiTrade');
        window.location.reload();

    }

    render() {
        return (
            <div>
                <div className="btn-container pb-5">
                    <div className="btn-save-primary" onClick={this.logout}>
                        <p>logOut</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LogoutComponent;