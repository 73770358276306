import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import Slider from '@material-ui/core/Slider';

import './style.scss';


const PrettoSlider = withStyles({
    root: {
        color: '#46ADD8',
        height: 2,
    },
    thumb: {
        height: 40,
        width: 40,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -16,
        marginLeft: -15,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 20px)',
    },
    track: {
        height: 12,
        borderRadius: 15,
    },
    rail: {
        height: 12,
        borderRadius: 15,
    }

})(Slider);


class BudgetSliderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            budget: 1000,
            fee: 90
        }


    }

    payment() {
        browserHistory.push({
            pathname: "/payment/",
            state: {
                fee: this.state.fee,
                budget: this.state.budget
            }
        })
    }

    render() {
        const handleSliderChange = (event, newBudget) => {
            this.setState({ budget: newBudget })
            const gain = this.state.budget * this.props.profitPercent / 100;
            this.setState({
                fee: this.state.budget * this.props.feePercentSetting / 100
            })
        };

        return (
            <div className="p-3">

                <div className="pb-3">
                    <h4>Budget</h4>
                </div>
                <div className="">
                    <PrettoSlider
                        valueLabelDisplay="off"
                        aria-label="slider"
                        step={100}
                        min={this.props.min}
                        max={this.props.max}
                        defaultValue={1000}
                        onChange={handleSliderChange} />
                </div>
                <div className="slider-min-max">
                    <p>Minimum <b>$600.00</b></p>
                    <p>Maximum <b>$10,000.00</b></p>
                </div>

                <div className="row budget-price">
                    <div className="col m-3 left-col">
                        <ul>
                            <li><p>Your Budget</p> <h2>${this.state.budget}.00</h2></li>
                            <li><p>Expected Period</p> <h4>~{this.props.periods} Days</h4></li>
                            <li><p>Profit Percentage</p> <h4>{this.props.profitPercent} %</h4></li>
                            <li><p>Profit Gain</p> <h4>${this.state.budget * this.props.profitPercent / 100}.00</h4></li>
                        </ul>
                    </div>
                    <div className="col m-3 right-col">
                        <ul>
                            <li>
                                <h4>Period Fee</h4>
                                <h1>${this.state.fee}.00</h1>
                            </li>
                            <li>
                                <div className="btn-login" onClick={() => this.payment()}>
                                    <p>Payment</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        );
    }
}

export default BudgetSliderComponent;