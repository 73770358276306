import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { browserHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCog } from '@fortawesome/free-solid-svg-icons'
import './style.scss';

class UserInfoComponent extends Component {

    navigateTo(key) {
        browserHistory.push(key);
    }

    render() {

        return (
            <div>
                <div className="user-info-container">
                    <div className="user-wallet">
                        <p className="user-full-name">{this.props.fullName}</p>
                    </div>
                    <div className="user-avatar"></div>
                    <div className="header-item" onClick={() => this.navigateTo('/setting')}>
                        <FontAwesomeIcon icon={faCog} />
                    </div>
                    <div className="header-item" onClick={() => this.navigateTo('/notifications')}>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UserInfoComponent;