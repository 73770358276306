import React, {Component} from 'react';
import RadarsComponent from '../../../component/dashboard/smartTraders/radars/radarsComponent';

import './style.scss'

class BotOrdersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="bot-orders-container">
                <RadarsComponent />
            </div>
         );
    }
}
 
export default BotOrdersComponent;